import { createRouter, createWebHistory } from 'vue-router'
import middleware from '@/router/middleware'
import { trackRouter } from 'vue-gtag-next'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior: () => ({ top: 0 }),
  routes: [
    {
      path: '/auth',
      component: () => import('@/ui/layouts/blank/BlankLayout.vue'),
      redirect: '/login',
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import('../views/LoginView.vue'),
        },
        {
          path: '/login/:provider/callback',
          name: 'loginCallback',
          component: () => import('../views/LoginView.vue'),
        },
        {
          path: '/forgot-password',
          name: 'forgotPassword',
          component: () => import('../views/ForgotPassword.vue'),
        },
        {
          path: '/reset-password',
          name: 'resetPassword',
          component: () => import('../views/ResetPasswordView.vue'),
        },
        {
          path: '/create-password',
          name: 'createPassword',
          component: () => import('../views/CreatePassword.vue'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/ui/layouts/full/FullLayout.vue'),
      redirect: '/home',
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/Home/HomeView.vue'),
        },
        {
          path: '/estoque/saldo',
          name: 'balance',
          component: () => import('@/views/Balance/BalanceView.vue'),
          meta: {
            permissions: ['balance-read'],
            modules: ['WORKER', 'COUPON'],
          },
        },
        {
          path: '/estoque/profissionais-saude',
          name: 'workerStock',
          component: () => import('@/views/Worker/WorkerStockView.vue'),
          meta: {
            permissions: ['workerstock-read', 'worker-read'],
            modules: ['WORKER'],
          },
        },
        {
          path: '/estoque/profissionais-saude/adicionar',
          name: 'addWorkerStock',
          component: () => import('@/views/Worker/AddWorkerStockView.vue'),
          meta: {
            permissions: ['workerstock-create', 'worker-create'],
            modules: ['WORKER'],
          },
        },
        {
          path: '/estoque/profissionais-saude/importar',
          name: 'importWorkerStock',
          component: () => import('@/views/Worker/ImportWorkerStockView.vue'),
          meta: {
            permissions: ['workerstock-upload'],
            modules: ['WORKER'],
          },
        },
        {
          path: '/estoque/cupons',
          name: 'couponStock',
          component: () => import('@/views/Coupon/CouponStockView.vue'),
          meta: {
            permissions: ['couponstock-read'],
            modules: ['COUPON'],
          },
        },
        {
          path: '/estoque/cupons/adicionar',
          name: 'addCouponStock',
          component: () => import('@/views/Coupon/AddCouponStockView.vue'),
          meta: {
            permissions: ['couponstock-create'],
            modules: ['COUPON'],
          },
        },
        {
          path: '/estoque/cupons/adicionar-lote',
          name: 'addCouponStockBatch',
          component: () => import('@/views/Coupon/AddCouponStockBatchView.vue'),
          meta: {
            permissions: ['couponstock-create'],
            modules: ['COUPON'],
          },
        },
        {
          path: '/dados/dashboard',
          name: 'dashboard',
          component: () => import('@/views/Dashboard/DashboardView.vue'),
          meta: {
            permissions: ['dashboard-read', 'dashboardcore-read'],
          },
        },
        {
          path: '/sistema/usuarios',
          name: 'admins',
          component: () => import('@/views/Admin/AdminView.vue'),
          meta: {
            permissions: ['user-read'],
          },
        },
        {
          path: '/sistema/usuarios/adicionar',
          name: 'addAdmin',
          component: () => import('@/views/Admin/AddAdminView.vue'),
          meta: {
            permissions: ['user-create', 'userstock-create'],
          },
        },
        {
          path: '/sistema/usuarios/importar',
          name: 'importAdmin',
          component: () => import('@/views/Admin/ImportAdminView.vue'),
          meta: {
            permissions: ['userstock-upload'],
          },
        },
        {
          path: '/sistema/configuracoes',
          name: 'config',
          component: () => import('@/views/Config/ConfigView.vue'),
          meta: {
            permissions: ['config-read'],
          },
        },
        {
          path: '/sistema/meu-perfil',
          name: 'profile',
          component: () => import('@/views/Profile/ProfileView.vue'),
        },
      ],
    },
  ],
})

router.beforeEach(middleware)

trackRouter(router)
export default router
