import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import router from '@/router'
import CookieService from '@/data/services/CookieService'
import ApiService from '@/data/services/ApiService'

export const useAuthStore = defineStore('auth', () => {
  const info = ref(CookieService.getData('info'))
  const adminInfo = ref(CookieService.getData('info')?.admin_auth_info)
  const companyInfo = ref(CookieService.getData('info')?.company_info)
  const loadingValidate = ref(false)

  const adminPermissions = computed(() => {
    return adminInfo.value?.permissions
  })

  const companyModules = computed(() => {
    return companyInfo.value?.modules
  })

  function setInfo(data) {
    CookieService.setData('info', data)
    info.value = data
    adminInfo.value = data.admin_auth_info
    companyInfo.value = data.company_info
  }

  function removeInfo() {
    CookieService.removeData('info')
    info.value = null
    adminInfo.value = null
    companyInfo.value = null
  }

  function getAdminInfo() {
    return CookieService.getData('info')?.admin_auth_info
  }

  async function refreshAdminInfo() {
    const { data } = await ApiService.post('admin/login/validate')
    adminInfo.value = data?.data.admin_auth_info
  }

  async function checkValidAuth(tokenAuth) {
    try {
      loadingValidate.value = true
      ApiService.defaults.headers['Authorization'] = `Bearer ${tokenAuth}`
      const response = await ApiService.post('admin/login/validate')
      setInfo(response?.data?.data)
      return response.data.data
    } catch (e) {
      removeInfo()
      router.push({ name: 'login' })
    } finally {
      loadingValidate.value = false
    }
  }

  return {
    info,
    setInfo,
    removeInfo,
    checkValidAuth,
    refreshAdminInfo,
    adminInfo,
    companyInfo,
    getAdminInfo,
    loadingValidate,
    adminPermissions,
    companyModules,
  }
})
