import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import Notifications from '@kyvg/vue3-notification'
import YoutubeIframe from '@techassi/vue-youtube-iframe'
import './assets/main.css'
import VueGtag from 'vue-gtag-next'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import resetStore from '@/ui/plugins/resetStore'
// Vuetify
import 'vuetify/styles'
import vuetify from '@/ui/plugins/vuetify'

import CanRoleDirective from '@/data/directives/CanRole'
import CanPermissionDirective from '@/data/directives/CanPermission'

import Skeleton from 'vue-loading-skeleton'
import 'vue-loading-skeleton/dist/style.css'

import 'floating-vue/dist/style.css'

import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

const app = createApp(App)

const pinia = createPinia()
pinia.use(resetStore)
pinia.use(piniaPluginPersistedstate)

app.use(vuetify)
app.use(pinia)
app.use(router)
app.use(Notifications)
app.use(YoutubeIframe)
app.use(Skeleton)
app.use(PerfectScrollbar)
app.directive('role', CanRoleDirective)
app.directive('permission', CanPermissionDirective)
app.use(VueGtag, {
  property: {
    id: import.meta.env.VITE_GOOGLE_ANALYTICS_ID,
  },
})

app.mount('#app')
