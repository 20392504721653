<script setup>
import { computed } from 'vue'
import IconError from '~icons/tabler/circle-x'
import IconWarning from '~icons/tabler/alert-circle'
import IconInfo from '~icons/tabler/info-circle'
import IconSuccess from '~icons/tabler/circle-check'
import IconRefresh from '~icons/tabler/file'

const props = defineProps({
  type: {
    type: String,
    default: 'info',
  },
  title: {
    type: String,
    default: '',
  },
  text: {
    type: String,
    default: '',
  },
  isButton: {
    type: Boolean,
    default: false,
  },
  refresh: {
    type: Boolean,
    required: false,
  },
  historic: {
    type: Boolean,
    required: false,
  },
  modalExcel: {
    type: Boolean,
    required: false,
  },
})

const emit = defineEmits(['close'])

const textColors = {
  error: ['tw-text-danger'],
  warning: ['tw-text-warning'],
  info: ['tw-text-info'],
  success: ['tw-text-success'],
  refresh: ['tw-text-[#0094DA]'],
  notification: ['tw-text-primary'],
}

const bgColors = {
  error: ['tw-bg-danger'],
  warning: ['tw-bg-warning'],
  info: ['tw-bg-info'],
  success: ['tw-bg-success'],
  refresh: ['tw-bg-[#0094DA]'],
  notification: ['tw-bg-primary'],
}

const borderColors = {
  error: ['tw-border-danger'],
  warning: ['tw-border-warning'],
  info: ['tw-border-info'],
  success: ['tw-border-success'],
  refresh: ['tw-border-[#0094DA]'],
  notification: ['tw-border-primary'],
}

const iconsType = {
  error: IconError,
  warning: IconWarning,
  info: IconInfo,
  success: IconSuccess,
  refresh: IconRefresh,
  notification: IconRefresh,
}

const textClasses = computed(() => {
  return textColors[props.type]
})

const bgClasses = computed(() => {
  return bgColors[props.type]
})

const borderClasses = computed(() => {
  return borderColors[props.type]
})

const iconComponent = computed(() => {
  return iconsType[props.type]
})

function close() {
  emit('close')
}
</script>

<template>
  <div
    class="tw-bg-white tw-shadow-md tw-rounded-md tw-border-t-4"
    :class="borderClasses"
  >
    <div class="tw-flex tw-justify-between tw-p-[14px]">
      <div class="tw-flex tw-items-start tw-gap-4">
        <div
          class="tw-rounded-full tw-flex tw-items-center tw-justify-center tw-p-1"
          :class="bgClasses"
        >
          <component :is="iconComponent" class="tw-text-white" />
        </div>
        <div class="tw-flex tw-flex-col tw-gap-2">
          <h3 class="tw-text-sm tw-font-black" :class="textClasses">
            {{ props.title }}
          </h3>
          <span class="tw-text-xs tw-text-textPrimary tw-font-medium">{{
            props.text.length > 150
              ? props.text.substring(0, 150) + '...'
              : props.text
          }}</span>
          <button
            v-if="props.isButton"
            type="button"
            @click="openModal"
            class="tw-bg-[#0094DA] tw-rounded-[5px] text-wh ite tw-py-[4px] tw-px-[8px] tw-font-medium tw-text-[12px] tw-text-center tw-mt-[4px]"
          >
            Consulte a lista de possíveis erros.
          </button>
        </div>
      </div>
      <div>
        <VBtn
          icon="mdi-close"
          @click="close"
          color="primary"
          variant="text"
          size="small"
          width="20px"
          height="20px"
        ></VBtn>
      </div>
    </div>
    <!--    <div v-if="props.refresh">-->
    <!--      <v-progress-linear indeterminate color="toast-primary" height="4" />-->
    <!--    </div>-->
  </div>
</template>
