<script setup>
import { RouterView } from 'vue-router'
import AlertNotification from '@/ui/components/display/AlertNotification/AlertNotification.vue'
import CookieConsent from '@/ui/components/CookieConsent/CookieConsent.vue'
import { useNotificationStore } from '@/stores/app/notification'

const { addToastNotification, removeToastNotification } = useNotificationStore()
</script>

<template>
  <div class="tw-w-full tw-h-full">
    <RouterView />
    <notifications
      class="tw-min-w-[95%] sm:tw-min-w-[400px] tw-p-0 tw-bg-none tw-border-none tw-mt-14 tw-mr-2"
      position="top right"
      :max="1"
      ignore-duplicates
      pause-on-hover
      @start="addToastNotification"
      @destroy="removeToastNotification"
    >
      <template #body="{ item, close }">
        <AlertNotification
          class="tw-m-2"
          :type="item.type"
          @close="close"
          :title="item.title"
          :text="item.text"
        />
      </template>
    </notifications>
    <CookieConsent />
  </div>
</template>
