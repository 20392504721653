import { useAuthStore } from '@/stores/auth'

export default function CanRoleDirective(el, binding) {
  const auth = useAuthStore()
  const adminInfo = auth.getAdminInfo()
  const role = binding.value
  let hasRoles = false

  if (typeof role === 'string') {
    hasRoles = adminInfo?.roles?.includes(role)
  }

  if (typeof role === 'undefined') hasRoles = true

  if (typeof role === 'object') {
    hasRoles = role.some((role) => adminInfo?.roles?.includes(role))
  }

  if (!hasRoles) {
    el.remove()
  }
}
