export const defaultPagination = {
  total: 0,
  last_page: 0,
  current_page: 0,
  limit: 10,
  page: 1,
  sortBy: [],
}

export const itemsPerPageOptions = [
  { value: 10, title: '10' },
  { value: 25, title: '25' },
  { value: 50, title: '50' },
  { value: 100, title: '100' },
]

export const getPaginationParams = (pagination) => {
  const params = {
    page: pagination.page,
    limit: pagination.limit,
  }
  if (pagination.sortBy && pagination.sortBy?.length) {
    params.sort_by = pagination.sortBy[0].key
    params.sort_direction = pagination.sortBy[0].order?.toUpperCase()
  }
  return params
}

export const setPaginationParams = (pagination, data) => {
  pagination.total = data?.total || 0
  pagination.last_page = data?.last_page || 0
  pagination.current_page = data?.current_page || 0
}
