import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import ApiService from '@/data/services/ApiService'
import { UPLOAD_NOTIFICATION } from '@/data/consts'
import { notify } from '@kyvg/vue3-notification'
import { useWorkerStockHistory } from '@/stores/stock/workerStockHistory.js'

export const useNotificationStore = defineStore('app-notifications', () => {
  const notifications = ref([])
  const loadingNotifications = ref(false)
  const notificationSize = computed(() => notifications.value.length)
  const isOpen = ref(false)

  const workerStockHistoryStore = useWorkerStockHistory()

  const activeToastNotifications = ref([])

  function addNotification(notification) {
    removeNotification(notification)
    notifications.value.push(notification)
  }

  function removeNotification(notification) {
    notifications.value = notifications.value.filter(
      (item) => item.id !== notification.id,
    )
  }

  async function getUploadStatus(show) {
    try {
      loadingNotifications.value = true
      removeNotification(UPLOAD_NOTIFICATION)
      const { data } = await ApiService.get('worker/upload/status')
      if (data?.data?.sheet_status?.processing) {
        addNotification(UPLOAD_NOTIFICATION)
        notify({
          title: 'Processando planilha...',
          text: 'Verifique o status de processamento no ícone de notificação.',
          type: 'notification',
          duration: 6000,
        })
      } else if (show) {
        notify({
          title: 'Processamento da planilha finalizado!',
          text: 'Para mais informações, consulte a aba histórico de importações.',
          type: 'success',
          duration: 6000,
        })
        workerStockHistoryStore.getStockHistory()
      }
    } catch (error) {
      removeNotification(UPLOAD_NOTIFICATION)
    } finally {
      loadingNotifications.value = false
    }
  }

  function addToastNotification(notification) {
    activeToastNotifications.value.push(notification)
  }

  function removeToastNotification(notification) {
    activeToastNotifications.value = activeToastNotifications.value.filter(
      (item) => item.id !== notification.id,
    )
  }

  function closeAllToast() {
    activeToastNotifications.value.forEach((notification) => {
      notify.close(notification?.id)
    })
  }

  return {
    loadingNotifications,
    notifications,
    addNotification,
    removeNotification,
    notificationSize,
    getUploadStatus,
    isOpen,
    addToastNotification,
    removeToastNotification,
    activeToastNotifications,
    closeAllToast,
  }
})
