import { useAuthStore } from '@/stores/auth'
import CookieService from '@/data/services/CookieService'

export default async function middleware(to, from, next) {
  if (to?.path !== from?.path) {
    await authVerify(to, from, next)
  } else next()
}

async function authVerify(to, from, next) {
  const auth = useAuthStore()
  const tokenWithRef = auth.info?.token
  const token = CookieService.getData('info')?.token

  if (to?.meta?.requiresAuth) {
    await verifyAuthenticated(next, tokenWithRef, auth, token, to)
  } else {
    await verifyNotAuthenticated(next, tokenWithRef, auth, token)
  }
}

async function verifyAuthenticated(next, tokenWithRef, auth, token, to) {
  if (token && tokenWithRef) {
    const isAuthenticated = await auth.checkValidAuth(token)
    if (!isAuthenticated) {
      next({ name: 'login' })
    } else {
      await verifyModules(next, to, auth)
    }
  } else {
    next({ name: 'login' })
  }
}

async function verifyNotAuthenticated(next, tokenWithRef, auth, token) {
  if (token && tokenWithRef) {
    const isAuthenticated = await auth.checkValidAuth(token)
    if (isAuthenticated?.token) {
      next({ name: 'home' })
    }
  } else {
    next()
  }
}

async function verifyModules(next, to, auth) {
  const { companyModules } = auth
  const routeModules = to.meta.modules

  if (!routeModules) {
    await verifyPermissions(next, to, auth)
    return
  }

  const hasModule = companyModules.some((module) =>
    routeModules?.includes(module),
  )

  if (!hasModule) {
    next({ name: 'home' })
    return
  } else {
    await verifyPermissions(next, to, auth)
  }
}

async function verifyPermissions(next, to, auth) {
  const { adminPermissions } = auth
  const routePermissions = to.meta.permissions

  if (!routePermissions || adminPermissions.includes('all-manage')) {
    next()
    return
  }

  const hasPermission = adminPermissions.some((permission) =>
    routePermissions?.includes(permission),
  )

  if (!hasPermission) {
    next({ name: 'home' })
    return
  } else {
    next()
    return
  }
}
