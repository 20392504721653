// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import { pt } from 'vuetify/locale'

export const themeCustom = {
  dark: false,
  colors: {
    background: '#FFFFFF',
    surface: '#FFFFFF',
    primary: '#7263A9',
    'primary-darken-1': '#3700B3',
    secondary: '#03DAC6',
    'secondary-darken-1': '#018786',
    error: '#ea5455',
    danger: '#ea5455',
    info: '#2196F3',
    success: '#2FBE71',
    warning: '#FB8C00',
    'toast-primary': '#0094DA',
  },
}

export default createVuetify({
  locale: {
    locale: 'pt',
    messages: { pt },
  },
  components: {
    ...components,
  },
  directives,
  theme: {
    defaultTheme: 'themeCustom',
    themes: {
      themeCustom,
    },
  },
  defaults: {
    VBtn: {
      color: 'primary',
      rounded: 'md',
      variant: 'flat',
      density: 'default',
    },
    VCard: {
      rounded: 'md',
    },
    VTextField: {
      rounded: 'lg',
      variant: 'outlined',
      density: 'comfortable',
      hideDetails: 'auto',
      color: 'primary',
    },
    VSelect: {
      rounded: 'lg',
      variant: 'outlined',
      desity: 'comfortable',
      hideDetails: 'auto',
    },
    VTooltip: {
      location: 'top',
    },
  },
})
