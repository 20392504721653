import Cookie from 'js-cookie'

const TOKEN_NAME = '_admin'
const config = {
  expires: 1,
  secure: false,
  sameSite: 'strict',
}

export default {
  setData(name, data) {
    Cookie.set(`${TOKEN_NAME}.${name}`, JSON.stringify(data), config)
  },

  getData(name) {
    return Cookie.get(`${TOKEN_NAME}.${name}`)
      ? JSON?.parse(Cookie.get(`${TOKEN_NAME}.${name}`))
      : null
  },

  removeData(name) {
    Cookie.remove(`${TOKEN_NAME}.${name}`)
  },
}
