import { useNotificationStore } from '@/stores/app/notification'

export const UPLOAD_NOTIFICATION = {
  id: 'worker-stock-upload',
  title: 'Processando planilha',
  message:
    'O processamento da planilha pode demorar um pouco. Clique em atualizar para confirmar quando estiver finalizada.',
  color: 'primary',
  icon: 'mdi-sync',
  actionTitle: 'Atualizar',
  actionFunction: () => {
    const store = useNotificationStore()
    store.getUploadStatus(true)
  },
}
