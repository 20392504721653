<script setup>
import { onMounted } from 'vue'

function verifyLocalhost() {
  const hostname = window.location.hostname
  return hostname.includes('localhost')
}

const loadCookieScript = () => {
  if (verifyLocalhost()) return
  const script = document.createElement('script')
  script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
  script.type = 'text/javascript'
  script.charset = 'UTF-8'
  script.setAttribute(
    'data-domain-script',
    'ff5af72f-404f-4fb0-8002-d97e5b8b5d0a',
  )
  document.head.appendChild(script)
}

onMounted(() => {
  loadCookieScript()
})
</script>

<template>
  <div></div>
</template>
