import { useAuthStore } from '@/stores/auth'

export default function CanPermissionDirective(el, binding) {
  const auth = useAuthStore()
  const adminInfo = auth.getAdminInfo()
  const permission = binding.value

  const isAllManage = adminInfo?.permissions?.includes('all-manage')

  let hasPermission = false

  if (typeof permission === 'string') {
    hasPermission = adminInfo?.permissions?.some((p) => p === permission)
  }

  if (typeof permission === 'string' && permission === '') {
    hasPermission = true
  }

  if (Array.isArray(permission)) {
    hasPermission = adminInfo?.permissions?.some((p) => permission.includes(p))
  }

  if (typeof permission === 'undefined' || isAllManage) {
    hasPermission = true
  }

  if (!hasPermission) {
    el.remove()
  }
}
