import ApiService from '@/data/services/ApiService'
import cloneDeep from 'lodash/cloneDeep'
import { defineStore } from 'pinia'
import { ref, watch, reactive } from 'vue'
import { notify } from '@kyvg/vue3-notification'
import { useAuthStore } from '@/stores/auth'
import {
  defaultPagination,
  getPaginationParams,
  setPaginationParams,
} from '@/data/utils'

const defaultFilters = {}

export const useWorkerStockHistory = defineStore('worker-stock-history', () => {
  const stockHistory = ref([])
  const pagination = reactive(cloneDeep(defaultPagination))
  const filters = ref(cloneDeep(defaultFilters))
  const search = ref('')
  const loadingStockHistory = ref(false)

  async function getStockHistory() {
    try {
      loadingStockHistory.value = true
      const params = formatParams()
      const { data } = await ApiService.post('worker/upload/search', params)
      stockHistory.value = data.data?.sheets_info || []
      setPaginationParams(pagination, data?.data)
    } catch (error) {
      stockHistory.value = []
      notify({
        title: 'Error',
        text: 'Erro ao obter estoque',
        type: 'error',
      })
    } finally {
      loadingStockHistory.value = false
    }
  }

  function formatParams() {
    const params = {
      filters: {
        search: search.value?.trim()?.length >= 3 ? search.value : '',
        ...filters.value,
      },
      ...getPaginationParams(pagination),
    }
    return params
  }

  function resetFilters() {
    filters.value = cloneDeep(defaultFilters)
    getStockHistory()
  }

  watch(
    () => [pagination.limit, pagination.page, pagination.sortBy],
    () => {
      const auth = useAuthStore()
      if (auth.info) getStockHistory()
    },
  )

  return {
    stockHistory,
    pagination,
    loadingStockHistory,
    getStockHistory,
    filters,
    search,
    resetFilters,
  }
})
