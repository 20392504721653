import * as Yup from 'yup'

export const schemaFilterWorkerStock = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .test('name', 'Digite pelo menos 3 caracteres', (value) => {
      if (value) {
        return value?.trim()?.length >= 3
      }
      return true
    }),
  last_sent: Yup.object().shape({
    min: Yup.string()
      .nullable()
      .test('min', 'Valor min inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
    max: Yup.string()
      .nullable()
      .test('max', 'Valor máx inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
  }),
  current_quantity: Yup.object().shape({
    min: Yup.string()
      .nullable()
      .test('min', 'Valor min inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
    max: Yup.string()
      .nullable()
      .test('max', 'Valor máx inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
  }),
  sent_quantity: Yup.object().shape({
    min: Yup.string()
      .nullable()
      .test('min', 'Valor min inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
    max: Yup.string()
      .nullable()
      .test('max', 'Valor máx inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
  }),
})

export const schemaFilterCouponStock = Yup.object().shape({
  coupon_code: Yup.string()
    .nullable()
    .test('name', 'Digite pelo menos 3 caracteres', (value) => {
      if (value) {
        return value?.trim()?.length >= 3
      }
      return true
    }),
  channel_name: Yup.string()
    .nullable()
    .test('name', 'Digite pelo menos 3 caracteres', (value) => {
      if (value) {
        return value?.trim()?.length >= 3
      }
      return true
    }),
  current_quantity: Yup.object().shape({
    min: Yup.string()
      .nullable()
      .test('min', 'Valor min inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
    max: Yup.string()
      .nullable()
      .test('max', 'Valor máx inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
  }),
  sent_quantity: Yup.object().shape({
    min: Yup.string()
      .nullable()
      .test('min', 'Valor min inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
    max: Yup.string()
      .nullable()
      .test('max', 'Valor máx inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
  }),
})

export const schemaFilterAdminstock = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .test('name', 'Digite pelo menos 3 caracteres', (value) => {
      if (value) {
        return value?.trim()?.length >= 3
      }
      return true
    }),
  email: Yup.string()
    .nullable()
    .test('email', 'Digite pelo menos 3 caracteres', (value) => {
      if (value) {
        return value?.trim()?.length >= 3
      }
      return true
    }),
  last_sent: Yup.object().shape({
    min: Yup.string()
      .nullable()
      .test('min', 'Valor min inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
    max: Yup.string()
      .nullable()
      .test('max', 'Valor máx inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
  }),
  current_quantity: Yup.object().shape({
    min: Yup.string()
      .nullable()
      .test('min', 'Valor min inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
    max: Yup.string()
      .nullable()
      .test('max', 'Valor máx inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
  }),
  sent_quantity: Yup.object().shape({
    min: Yup.string()
      .nullable()
      .test('min', 'Valor min inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
    max: Yup.string()
      .nullable()
      .test('max', 'Valor máx inválido', (value) => {
        if (value) {
          return Number(value) <= 1000000
        }
        return true
      }),
  }),
})

export const schemaFilterWorkerRecharge = Yup.object().shape({
  name: Yup.string()
    .nullable()
    .test('name', 'Digite pelo menos 3 caracteres', (value) => {
      if (value) {
        return value?.trim()?.length >= 3
      }
      return true
    }),
})
